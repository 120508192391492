/**
 * adds space after every 4 numbers ==> 90909090 --> 9090 9090
 * @param accountNumber
 */
export const beautifyAccountNumber = (accountNumber: number | string) =>
    (accountNumber || '')
        .toString()
        .replace(/(.{4})/g, '$1 ')
        .trim();

/**
 * mask all non-whitespace characters to X
 * i.e 'adfj aksfajsldfjsldfajsf faf' --> 'XXXX XXXXXXXXXXXXXXXXXXX faf'
 * @param accNumber
 */
export const maskCharacters = (accNumber: string): string => {
    if (!accNumber) return '';
    if (accNumber.length <= 4) return accNumber;
    return (
        accNumber.substr(0, accNumber.length - 4).replace(/\S/g, 'X') +
        accNumber.substr(accNumber.length - 4, accNumber.length)
    );
};
export const capitalizeWord = (text: string) =>
    text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();

export const capitalizeWords = (words: string): string => {
    if (typeof words !== 'string') return '';
    return words
        .split(' ')
        .map(capitalizeWord)
        .join(' ');
};
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
};
/**
 * howToDoThis ===> ["", "how", "To", "Do", "This"]
 * @param word word to be split
 */
export const splitCamelCaseWords = (word: string) => {
    if (typeof word !== 'string') return [];
    return word.replace(/([A-Z]+|[A-Z]?[a-z]+)(?=[A-Z]|\b)/g, '!$&').split('!');
};
